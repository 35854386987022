<template>
<b-card v-if="users" no-body class="card-company-table">
    <b-table :items="users" responsive :fields="fields" class="mb-0">
        <!-- Id -->
        <template #cell(id)="data">
            <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
        </template>
        <!-- CreatedAt Time -->
        <template #cell(createdTime)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.createdTime | moment("DD.MM.YYYY hh:mm")
          }}</span>
        </template>

        <!-- Status -->
        <template #cell(isActive)="data">
            <b-badge pill :variant="data.item.isActive?'light-success':'light-danger'">
                {{data.item.isActive?'AKTİF':'PASİF'}}
            </b-badge>
        </template>
        <template #cell(image)="data">
            <img class="font-weight-bolder mb-12" style="max-height:100px" :src="data.item.imageUrl" />
        </template>

        <!-- Actions -->
        <template #cell(actions)="data">
            <b-button variant="flat-primary" @click="openGroupDetails(data.item.id)">
                <feather-icon icon="EditIcon" class="mr-50" />
                <span class="align-middle">Detaylar</span>
            </b-button>
            <b-button variant="flat-warning" @click="editGain(data.item)">
                <feather-icon icon="EditIcon" class="mr-50" />
                <span class="align-middle">Düzenle</span>
            </b-button>
        </template>
    </b-table>
    <!--Open Add Group-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="addGainPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Yeni Grup Ekle</h5>
                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>
            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Grup Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addGroupRequest.name" />
                </b-form-group>
                <b-form-group label="Grup Resmi" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addGroupRequest.imageUrl" />
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="addGroup">
                        Ekle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>

    </b-sidebar>

    <!--Open Edit Group-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="editGainPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Grup Düzenle</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Grup Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="editGrupRequest.name" />
                </b-form-group>
                <b-form-group label="Grup Resmi" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="editGrupRequest.imageUrl" />
                </b-form-group>
                <b-form-group label="Yayınlama" label-for="from">
                    <b-form-checkbox v-model="editGrupRequest.isActive">
                        Öğrencilere Yayınlansın
                    </b-form-checkbox>
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="updateGroup">
                        Güncelle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>

    </b-sidebar>

    <!--Open Details Group-->
    <b-sidebar sidebar-class="sidebar-lg" width="50%" bg-variant="white" shadow backdrop no-header right v-model="openDetails">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Grup Detayları</h5>
                <span class="ml-1 cursor-pointer" @click="addGroupUserPanel=true">
                    <feather-icon icon="PlusIcon" size="16" />
                    Kullanıcı Ekle
                </span>
                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <table class="table b-table" v-if="details">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Kullanıcı Resmi</th>
                        <th scope="col">Kullanıcı Adı Soyadı</th>
                        <th scope="col">İşlemler</th>
                    </tr>
                </thead>
                <tr v-for="(item,index) in details" :key="item.id">
                    <td><span class="font-weight-bolder mb-12">#{{index+1 }}</span></td>
                    <td> <img class="font-weight-bolder mb-12" style="max-height:100px" :src="item.profileImage" /></td>
                    <td> <span class="font-weight-bolder mb-12">{{item.userName}}</span></td>
                    <td>
                        <feather-icon icon="DeleteIcon" @click="deleteUser(item.referanceId)" />
                    </td>
                </tr>
            </table>
        </template>
    </b-sidebar>

    <!--Add Test Question --->
    <b-modal title="Kullanıcı Ekle" ok-title="Ekle" size="xl" ok-only cancel-variant="outline-secondary" no-close-on-backdrop v-model="addGroupUserPanel" @ok="addGroupUser">
        <selected-student @AddUser="addUser" />
        <b-overlay :show="show" no-wrap>
        </b-overlay>
    </b-modal>

</b-card>
</template>

<script>
import {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BOverlay
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SelectedStudent from './PageComponent/SelectedStudent.vue'
export default {
    components: {
        BOverlay,
        SelectedStudent,
        vSelect,
        ToastificationContent,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup
    },
    data() {
        return {
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'createdTime', label: 'Kayıt Tarihi' },
                { key: 'isActive', label: 'Durum' },
                { key: 'image', label: 'Grup Resmi' },
                { key: 'name', label: 'Grup Adı' },
                { key: 'actions', label: 'İşlemler' },
            ],
            users: [],
            addGainPanel: false,
            editGainPanel: false,
            addGroupRequest: {
                name: '',
                imageUrl: ''
            },
            editGrupRequest: {
                groupId: 0,
                name: '',
                imageUrl: '',
                isActive: false
            },
            addGroupUserRequest: {
                userId: 0
            },
            openDetails: false,
            details: [],
            allUsers: [],
            selectedUser: [],
            addGroupUserPanel: false,
            selectedGroup: {},
            show: false
        }
    },
    mounted() {
        this.$root.$on('AddButtonClick', () => {
            this.addGainPanel = true;
        });
    },
    created() {
        this.getData();
        this.getUserData();
    },
    methods: {
        async getData() {
            var users = await this.$http.get("Message/GroupTeacher");
            this.users = users.data.data;
        },
        addUser(users) {
            this.selectedUser = users;
            //console.log(this.selectedUser);
        },
        async getUserData() {
            var allUsers = await this.$http.get("Teacher/AllStudent");
            this.allUsers = allUsers.data.data;
        },
        editGain(data) {
            this.editGrupRequest = {
                groupId: data.id,
                name: data.name,
                imageUrl: data.imageUrl,
                isActive: data.isActive
            };
            this.editGainPanel = true;
        },
        addGroup() {
            var request = this.addGroupRequest;
            this.$http.post("Message/AddGroup", request).then((data) => {
                    this.getData();
                    this.addGainPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Grup Oluşturuldu",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        updateGroup() {
            var request = this.editGrupRequest;
            this.$http.put("Message/UpdateGroup", request).then((data) => {
                    this.getData();
                    this.editGainPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Grup Güncellendi",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        async openGroupDetails(messageGroupId) {
            this.selectedGroup = messageGroupId;
            var groupUser = await this.$http.get("Message/GroupDetails/" + messageGroupId);
            this.details = groupUser.data.data;
            this.openDetails = true;
        },
        async addGroupUser(e) {

            this.show = true;
            e.preventDefault();
            var addReques = {
                "messageGroupId": this.selectedGroup,
                "users": []
            };
            this.selectedUser.forEach(user => {
                addReques.users.push(user.id);
            });

            this.$http.post("Message/AddGroupDetails", addReques).then((data) => {
                    this.openGroupDetails(this.selectedGroup);
                    this.addGroupUserPanel = false;
                    this.show = false;
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                    this.show = false;
                });
        },
        async deleteUser(detailsId) {
            await this.$http.delete("Message/DeleteUser/" + detailsId);
            this.openGroupDetails(this.selectedGroup);
        }
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style><style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
